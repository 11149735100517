//  TODO: add guest checkout back in phase 3

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
// import { Link as MuiLink } from '@mui/material';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { generatePath } from 'utils/route.utils';

import { Routes } from 'constants/routes.constants';
import { useAuth } from 'hooks/useAuth';
import { useCountries } from 'hooks/useCountries';
import { useInAuth } from 'hooks/useInAuth';
import { useMultiStepActions } from 'store/multistep';

import AuthLoadingOverlay from 'components/@auth/AuthLoadingOverlay';
import SocialLogin from 'components/@auth/SocialLogin';
import Link from 'components/@common/Link';
import Form from 'components/@form/Form';
// import Checkbox from 'components/@inputs/Checkbox';
import LoginForm from 'components/@login/LoginForm';

import { LoginFormFields } from './Login.types';
import { LoginValidationSchema } from './Login.validation';

interface FormData {
  email: string;
  password: string;
  remember: boolean;
}

const defaultValues = {
  [LoginFormFields.Email]: '',
  [LoginFormFields.Password]: '',
  [LoginFormFields.Remember]: true,
};

// enum CheckedTypes {
//   Register = 'register',
//   Guest = 'guest',
// }

const Login = () => {
  // const ref = useRef<HTMLDivElement>(null);
  // const isMobile = useMediaQuery((theme: Theme) =>
  //   theme.breakpoints.down('md'),
  // );
  // const [checked, setChecked] = useState<CheckedTypes | undefined>();
  const { senderCountry, receiverCountry } = useCountries();
  const inAuthId = useInAuth();
  const router = useRouter();
  const {
    login,
    isLoading,
    isLoggedIn,
    socialLogin,
    loginError,
    socialLoginError,
  } = useAuth();
  const { to, getStepIndex } = useMultiStepActions();

  // const fromCheckout = router.query.from === 'checkout';

  const trackAuthenticationView = useCallback(async () => {
    const { waitForAvo } = await import('services/avo');
    const { viewAuthentication } = await import('services/avo/avo');

    await waitForAvo();
    viewAuthentication();
  }, []);

  useEffect(() => {
    trackAuthenticationView();
  }, [trackAuthenticationView]);

  const handleLoginSuccess = () => {
    const redirectUrl = router.query['redirect'];

    if (router.query['from'] === 'order_overview') {
      to(getStepIndex('purchase'));
    }

    if (redirectUrl && typeof redirectUrl === 'string') {
      router.replace(redirectUrl);
    } else {
      // Go to home page
      router.replace({
        pathname: Routes.Root,
        query: { senderCountry, receiverCountry },
      });
    }
  };

  const handleSubmit = ({ email, password, remember }: FormData) => {
    login(
      {
        username: email,
        password,
        remember,
        inauthId: inAuthId(),
        activationUrl: `${window.location.origin}${generatePath(
          Routes.Activate,
          {
            token: `{activationToken}`,
          },
        )}`,
      },
      {
        onSuccess: handleLoginSuccess,
      },
    );
  };

  const handleSocialLogin = (
    token: string,
    provider: 'google-authcode' | 'facebook',
  ) => {
    socialLogin(
      { token, provider, redirectUrl: window.location.origin },
      {
        onSuccess: handleLoginSuccess,
      },
    );
  };

  // const handleSelect = (type: CheckedTypes) =>
  //   setChecked((state) => (state === type ? undefined : type));

  // const handleContinueGuestCheckout = () => {
  //   const isRegister = checked === CheckedTypes.Register;
  //   router.push({
  //     pathname: isRegister ? Routes.Register : Routes.Guest,
  //     query: isRegister ? { from: 'checkout' } : undefined,
  //   });
  // };

  // const handleScrollToRegister = () => {
  //   ref.current?.scrollIntoView({ behavior: 'smooth' });
  // };

  return (
    <Paper
      elevation={0}
      sx={{ p: 6, position: 'relative', overflow: 'hidden' }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          // md={fromCheckout ? 6 : 12}
          pr={0}
          // pr={{ md: fromCheckout ? 7 : 0 }}
        >
          <Stack gap={4}>
            <Typography variant="h1" color="primary">
              <FormattedMessage id="login.title" />
            </Typography>
            {loginError && (
              <Alert severity="error">
                {loginError.message ? (
                  loginError.message
                ) : (
                  <FormattedMessage id="login.error.general" />
                )}
              </Alert>
            )}
            <Form<FormData>
              onSubmit={handleSubmit}
              defaultValues={defaultValues}
              // @ts-expect-error TODO: Improve typings.
              resolver={yupResolver(LoginValidationSchema)}
            >
              <LoginForm disabled={isLoading || isLoggedIn} />
              <Button
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
                fullWidth
                disabled={isLoading || isLoggedIn}
              >
                <FormattedMessage id="login.button.label" />
              </Button>
            </Form>
            <Divider>
              <Typography variant="body2" color="inherit" sx={{ width: 50 }}>
                <FormattedMessage id="login.divider.text" />
              </Typography>
            </Divider>
            {socialLoginError && (
              <Alert severity="error">
                {socialLoginError.sourceCode === 'NotConnected' ? (
                  <FormattedMessage id="login.error.account_exist" />
                ) : (
                  <>
                    {socialLoginError.message ? (
                      socialLoginError.message
                    ) : (
                      <FormattedMessage id="login.error.general" />
                    )}
                  </>
                )}
              </Alert>
            )}
            <SocialLogin onSocialLogin={handleSocialLogin} />
          </Stack>
          {(isLoading || isLoggedIn) && <AuthLoadingOverlay />}
          {/* {fromCheckout ? (
            <MuiLink
              display={{ xs: 'block', md: 'none' }}
              onClick={handleScrollToRegister}
              textAlign="center"
              mt={6}
            >
              <FormattedMessage id="login.no_account.label" />
            </MuiLink>
          ) : (
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 6 }}>
              <FormattedMessage id="login.no_account.label" />
              <Link href={{ pathname: Routes.Register }} sx={{ ml: 2 }}>
                <FormattedMessage id="login.no_account.signup.label" />
              </Link>
            </Typography>
          )} */}
          <Typography variant="body1" sx={{ textAlign: 'center', mt: 6 }}>
            <FormattedMessage id="login.no_account.label" />
            <Link
              href={{
                pathname: Routes.Register,
                query: router.query,
              }}
              sx={{ ml: 2 }}
            >
              <FormattedMessage id="login.no_account.signup.label" />
            </Link>
          </Typography>
        </Grid>
        {/* {fromCheckout && (
          <Grid item xs={12} md={6} mt={{ xs: 13, md: 0 }}>
            <Box display={{ md: 'flex' }}>
              <Divider
                orientation={isMobile ? 'horizontal' : 'vertical'}
                flexItem
              />
              <Box ref={ref} pl={{ md: 7 }} pt={{ xs: 9, md: 0 }}>
                <Typography mb="14px" variant="h1" color="primary">
                  <FormattedMessage id="login.guest_checkout.title" />
                </Typography>
                <Box>
                  <Checkbox
                    onChange={() => handleSelect(CheckedTypes.Register)}
                    checked={checked === CheckedTypes.Register}
                    label={
                      <Typography variant="h4">
                        <FormattedMessage id="login.guest_checkout.checkbox.register.label" />
                      </Typography>
                    }
                  />
                  <Box pl="42px">
                    <FormattedMessage
                      id="login.guest_checkout.checkbox.register.description"
                      values={{
                        p: (chunks) => <Typography>{chunks}</Typography>,
                        ul: (chunks) => (
                          <List
                            sx={{
                              listStyle: 'inside',
                              color: 'brand.darkBlue',
                            }}
                          >
                            {chunks}
                          </List>
                        ),
                        li: (chunks) => (
                          <ListItem
                            sx={{ display: 'list-item', pt: 0, pb: 0, pl: 3 }}
                          >
                            {chunks}
                          </ListItem>
                        ),
                      }}
                    />
                  </Box>
                </Box>
                <Box mb={15}>
                  <Checkbox
                    onChange={() => handleSelect(CheckedTypes.Guest)}
                    checked={checked === CheckedTypes.Guest}
                    label={
                      <Typography variant="h4">
                        <FormattedMessage id="login.guest_checkout.checkbox.guest.label" />
                      </Typography>
                    }
                  />
                  <Typography pl="42px">
                    <FormattedMessage id="login.guest_checkout.checkbox.guest.description" />
                  </Typography>
                </Box>
                <Button
                  disabled={!checked}
                  onClick={handleContinueGuestCheckout}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  <FormattedMessage id="topup.payment.method.continue.button" />
                </Button>
              </Box>
            </Box>
          </Grid>
        )} */}
      </Grid>
    </Paper>
  );
};

export default Login;
