import { Box, BoxProps, ButtonBase, Container } from '@mui/material';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

import { Routes } from 'constants/routes.constants';
import { useCountries } from 'hooks/useCountries';

import Footer from 'components/@footer/Footer';
import DigicelLogomark from 'components/@icons/DigicelLogomark';
import DigicelWordmark from 'components/@icons/DigicelWordmark';

interface Props {
  children: ReactNode;
  maxWidth?: BoxProps['maxWidth'];
}

const AuthPageContainer = ({ children, maxWidth = 400 }: Props) => {
  const router = useRouter();
  const { senderCountry, receiverCountry } = useCountries();

  const handleLogoClick = () => {
    if (!senderCountry && !receiverCountry) {
      router.push({ pathname: '/' });
    } else {
      router.push({
        pathname: Routes.Root,
        query: {
          senderCountry,
          receiverCountry,
        },
      });
    }
  };

  return (
    <Box
      bgcolor={{ xs: 'support.neutral01', sm: 'background.default' }}
      minHeight="100vh"
      display="flex"
      flexDirection="column"
    >
      <ButtonBase disableRipple onClick={handleLogoClick} aria-label="home">
        <Box
          height={34}
          color="brand.red"
          display="flex"
          justifyContent="center"
          my={10}
        >
          <DigicelLogomark height="100%" width={50} fill="currentColor" />
          <DigicelWordmark height="100%" width={80} fill="currentColor" />
        </Box>
      </ButtonBase>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ flex: 1, maxWidth, mb: 15 }}
      >
        {children}
      </Container>
      <Footer minimized />
    </Box>
  );
};

export default AuthPageContainer;
