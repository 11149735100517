import { object, string } from 'yup';

import { emailRegex } from 'constants/validation.constants';

import { LoginFormFields } from './Login.types';

export const LoginValidationSchema = object().shape({
  [LoginFormFields.Password]: string()
    .trim()
    .required('login.field.password.error.required'),
  [LoginFormFields.Email]: string()
    .trim()
    .required('login.field.email.error.required')
    .matches(emailRegex, 'login.field.email.error.invalid'),
});
