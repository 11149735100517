//  TODO: add guest checkout back in phase 3

// import { useRouter } from 'next/router';
import { getCookie } from 'cookies-next';
import { GetServerSidePropsContext } from 'next';
import { useIntl } from 'react-intl';

import { TokenStorage } from 'types/auth.types';

import AuthPageContainer from 'components/@auth/AuthPageContainer';
import SEO from 'components/@head/SEO';
import Login from 'components/@login/Login';

const LoginPage = () => {
  const intl = useIntl();
  // const { query } = useRouter();
  // const fromCheckout = query.from === 'checkout';

  return (
    <>
      <SEO
        title={intl.formatMessage({ id: 'login.seo.title' })}
        nofollow
        noindex
      />
      <AuthPageContainer
      // maxWidth={fromCheckout ? 768 : undefined}
      >
        <Login />
      </AuthPageContainer>
    </>
  );
};

export const getServerSideProps = async ({
  req,
  res,
}: GetServerSidePropsContext) => {
  const accessToken = getCookie(TokenStorage.AccessToken, { req, res });

  if (accessToken) {
    return {
      redirect: {
        permanent: false,
        destination: '/',
      },
    };
  }

  return {
    props: {},
  };
};

export default LoginPage;
