import { Stack } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { testId } from 'utils/test-id.utils';

import { Routes } from 'constants/routes.constants';

import Link from 'components/@common/Link';
import FormCheckbox from 'components/@form/FormCheckbox';
import FormTextField from 'components/@form/FormTextField';
import { LoginFormFields } from 'components/@login/Login/Login.types';

interface Props {
  disabled?: boolean;
}

const LoginForm = ({ disabled = false }: Props) => {
  const intl = useIntl();

  return (
    <Stack alignItems="flex-start">
      <FormTextField
        name={LoginFormFields.Email}
        label={intl.formatMessage({ id: 'login.field.email.label' })}
        placeholder={intl.formatMessage({
          id: 'login.field.email.placeholder',
        })}
        autoComplete="email"
        type="email"
        rules={{ required: true }}
        disabled={disabled}
        inputProps={{ ...testId('input-login-email') }}
      />

      <FormTextField
        name={LoginFormFields.Password}
        label={intl.formatMessage({ id: 'login.field.password.label' })}
        placeholder={intl.formatMessage({
          id: 'login.field.password.placeholder',
        })}
        type="password"
        autoComplete="off"
        sx={{ mt: 5, mb: 4 }}
        rules={{ required: true }}
        disabled={disabled}
        inputProps={{ ...testId('input-login-password') }}
      />
      <Link
        underline="always"
        href={{ pathname: Routes.ForgotPassword }}
        sx={{ mb: 3 }}
      >
        <FormattedMessage id="login.forgot_password.button.label" />
      </Link>
      <FormCheckbox
        name={LoginFormFields.Remember}
        label={intl.formatMessage({ id: 'login.stay_logged_in.label' })}
        disabled={disabled}
      />
    </Stack>
  );
};

export default LoginForm;
